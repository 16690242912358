import { PluginProduction } from "../../interface/production";
export class EasyMenu implements PluginProduction{
    getName(): string {
        return "EasyMenu"
    }
    getCreateVersion(): string {
        return "1.20.1"
    }
    getDescription(): string[] {
        return ["簡単にサーバー用のメニューを作ることのできるプラグイン"]
    }
    getCommands(): { command: string; description: string[]; permission: string; }[] {
        return [
            {command: "/em create <id>", description:["idで指定したメニューを作成する"], permission: "easymenu.create"},
            {command: "/em list", description:["作成されているメニューの一覧を表示する"], permission: "easymenu.list"},
            {command: "/em edit <id>", description:["idで指定したメニューを編集する"], permission: "easymenu.edit"},
            {command: "/em open <id>", description:["idで指定したメニューを表示する"], permission: "easymenu.open"},
            {command: "/menu", description:["Configで指定したidのメニューを表示する"], permission: "easymenu.menu"},
        ]
    }
    getYoutubeId(): string {
        return "";
    }
    getGithubUrl(): string {
        return "";
    }
    getReleaseUri(): string {
        return "";
    }
    
}