import Skill from './components/pages/Skill';
import TopPage from './components/pages/TopPage';
import TopWork from './components/pages/TopWork';
import Header from './components/ui/Header';

function App() {
  return (
    <div className="App">
      <Header/>
      <TopPage/>
      <Skill/>
      <TopWork/>
    </div>
  );
}

export default App;
