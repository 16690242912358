import React from 'react'
import css from "../../css/work.module.css";
import { EasyMenu } from '../plugins/easymenu';

const TopWork = () => {
    const easyMenu = new EasyMenu();
  return (
    <div className={css.TopWorkField}>
        <div className={css.Logo}>Work</div>
        <div className={css.TopContents}>
            
        </div>
    </div>
  )
}

export default TopWork