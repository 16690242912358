import React from 'react'
import css from "../../css/skill.module.css";
import { useWindowSize } from "../../hooks/windowSize";

const Skill = () => {
    const [width, height] = useWindowSize();

    return (
        <div className={css.SkillField} id='skill'>
            <div className={css.Logo}>Skill</div>
            <div className={css.SkillContents} style={width <= 675 ? {display: "block"} : {}}>
                <div className={css.Skill} style={width <= 675 ? {width: "95%", margin: "0 auto", marginBottom: "20px"} : {}}>
                    <div className={css.SkillLogo}>Minecraft Plugin</div>
                    <div className={css.Contents}>
                       Javaを用いてSpigot、Paper、BungeeCord等のプラグイン作成をすることができます。
                    </div>
                </div>
                <div className={css.Skill} style={width <= 675 ? {width: "95%", margin: "0 auto", marginBottom: "20px"} : {}}>
                    <div className={css.SkillLogo}>HomePage</div>
                    <div className={css.Contents}>
                        html、css、Reactなどを用いてホームページ作成をすることができます。
                    </div>
                </div>
                <div className={css.Skill} style={width <= 675 ? {width: "95%", margin: "0 auto", marginBottom: "20px"} : {}}>
                    <div className={css.SkillLogo}>WebAPI</div>
                    <div className={css.Contents}>
                        PHPやNodeJS × expressを用いてWebAPIを作成することができます。
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill