import React, { useEffect, useState } from 'react'
import css from "../../css/header.module.css";
import { FaGithub, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useWindowSize } from '../../hooks/windowSize';

const Header = () => {
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [width, height] = useWindowSize();
    const openMenu = () => {
        setOpen(!open);
        if (!load) {
            setLoad(true);
        }
    }

    return (
        <div className={css.HeaderField}>
            <div className={css.Hunberger} onClick={() => openMenu()}>
                <div className={css.HunbergerArea} style={open ? { transform: "rotate(360deg)" } : {}}>
                    <span style={open ? { top: "16px", left: "18px", transform: "translateY(8px) rotate(-45deg)", width: "30%" } : {}}></span>
                    <span style={open ? { opacity: 0 } : {}}></span>
                    <span style={open ? { top: "32px", left: "18px", transform: "translateY(-8px) rotate(45deg)", width: "30%" } : {}}></span>
                </div>
            </div>
            {open ? <div className={css.LinkField}>
                <div className={css.MenuLogo}><h1>Kaogon's Portfolio</h1></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#about">About</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#skill">Skill</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#work">Work</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#content">Content</a></div>
                <div className={css.SNSField} style={width <= 675 ? { width: "40%" } : {}}>
                    <div className={css.SNSIcon}><FaXTwitter /></div>
                    <div className={css.SNSIcon}><FaYoutube /></div>
                    <div className={css.SNSIcon}><FaGithub /></div>
                </div>
            </div> : load ? <div className={css.LinkFieldClose}>
                <div className={css.MenuLogo}><h1>Kaogon's Portfolio</h1></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#about">About</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#skill">Skill</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#work">Work</a></div>
                <div className={css.Link} onClick={()=>setOpen(false)}><a href="#content">Content</a></div>
                <div className={css.SNSField} style={width <= 675 ? { width: "40%" } : {}}>
                    <div className={css.SNSIcon}><FaXTwitter /></div>
                    <div className={css.SNSIcon}><FaYoutube /></div>
                    <div className={css.SNSIcon}><FaGithub /></div>
                </div>
            </div> : <></>}
        </div>

    )
}

export default Header